/* src/components/NotFound.css */
.not-found {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 0, 0);
    color: rgb(106, 103, 103);
  }
  
  .not-found-content {
    text-align: center;
  }
  
  h1 {
    font-size: 4rem;
  }
  
  p {
    font-size: 1.5rem;
  }
  