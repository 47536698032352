.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Blur the background */
    display: none;
    align-items: center;
    justify-content: center;
  }
  
  .overlay.active {
    display: flex;
  }
  
  .popup {
    background: white;
    border-radius: 10px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    animation: slide-up 0.5s ease-out; /* Slide-up animation */
  }
  
  @keyframes slide-up {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  