/* UserProfile.css */

/* Slide-in animation */
@keyframes slideIn {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  /* Slide-out animation */
  @keyframes slideOut {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }
  
  .slide-in {
    animation: slideIn 0.7s ease-in forwards;
  }
  
  .slide-out {
    animation: slideOut 0.7s ease-out forwards;
  }
  