@import url('https://cdn.syncfusion.com/ej2/material.css');


  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');


.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000000;
  }
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device{
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}


/* landing page */

body{
  background-image: url('/src/Landing_pageBack.png');
  background-size: 100%;
  background-repeat: repeat-y;
 
 
}
.bottom{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.font{
  font-size: 30px;
  font-weight: bold;
  font-family:'Poppins', sans-serif;;
}
.para{
  color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 148.5%; /* 26.73px */
  
}
.loginHome{
  font-size: 20px;
  padding: 4px;
  width: 100px;
  
  font-weight: bold;
  color: white;
  border-radius: 10px;

}
.loginbt{
  border: 1px solid white;
 padding-top: 6px;
 padding-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: poppins;
  font-weight: bold;
  font-size: 20px;
  color: white;
  border-radius: 20px;
  

}
a{
  text-decoration: none;
}
.loginbt:hover{
  transform: scale(1.1);
  background-color: #E88E1A;
  border: none;
}
.hr{
  height: 1px;
  background-color: black;
}
.intern{
  color: #E88E1A;
text-align: center;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 156.5%; /* 62.6px */
}
.achieve{
  color: #1E1E1E;
text-align: center;
font-family: Poppins;
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: 156.5%; /* 39.125px */
  border-bottom: 3px solid #E88E1A ;
  
}
.achieved{
  color: #1E1E1E;
text-align: center;
font-family: Poppins;
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: 156.5%; /* 39.125px */
}
.track{
  text-align: center;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 156.5%; /* 62.6px */
  
}
.achievement{
  margin-top: 60px;
  font-size: 25px;  
  margin-bottom: 20px;
}
.students{
  color: #E8A957;
  font-family: Poppins;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 120.5%; /* 60.25px */
  padding-left: 10px;
 


}

.topdown{
  margin-top: 2rem ;
}
.studentIcon{
  width: 60px;
  height: 60px;
  flex-shrink: 0;
}
.side{
  padding-left: 40px;
}
.backimage{
  padding-right: 20px;
}
.images{
  padding-left: 80px;
}
.logo{
  width: 20rem;


}
.course{
  width: 60px;
  height: 60px;
  border-radius: 21px;
background-color: #FFF;
box-shadow: 0px 0px 4px 1px rgba(232, 142, 26, 0.25);
flex-shrink: 0;
padding: 9px;
animation: animate 2s infinite;
}
.size{
  font-size: 14px;
  font-weight: 100;
  color:black
}
.support{
  color: white;

}
.bg{
  color: #E88E1A;
}
.bg:hover{
  color: #9b6218;
}






  .box{
      position: relative;
      width:380px;
      height: 420px;
      border-radius: 8px;
      overflow:hidden;
      border:none;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    
  }
  .margin{
    margin-top: -50px;
  }

.form{
  position: absolute;
  inset: 3px;
  border-radius: 8px;
  background-color: white;
  z-index:10;
  align-content: center;
  margin: auto;
}
.loginHome{
  padding: 20px;
  padding-top: 0px;
  width: 20rem;
  margin: auto;
  align-content: center;
  margin-top: 15%;
  color: #000;
}

.font{
  color: #E88E1A;
  margin-top: 20px;
}

.loginInput{
  border-bottom: 1px solid #E88E1A;
  margin-bottom: 10px;
  border-radius: 0px;
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  body {
    background-image: url('/src/Landing_pageBack.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  }
  .box{
    position: relative;
    width:280px;
    height: 320px;
    border-radius: 8px;
    overflow:hidden;
    border:none;
  
}
.form{
  position: absolute;
  inset: 3px;
  border-radius: 8px;
  background-color: white;
  z-index:10;
  align-content: center;
  margin: auto;
 
 
 
}

}


.height{
  height: 40rem;
  
}





.app {
  /* text-align: center; */
  margin: 0 auto;
  width: 50%;
}

.selected-date {
  margin: 0 auto;
}

.times button {
  display: block;
  background-color: #6f48eb;
  color: white;
  margin-top: 5px;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



/* react calendar */


.react-calendar { 
  width: 700px;
  max-width: 100%;
  color: black;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
 }

 .react-calendar__navigation button {
  color: black;
  background-color: grey;
  min-width: 44px;
  background:none;
  font-size: 16px;
  margin-top: 8px;
 }

.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background-color: grey;
  /* background: none; */
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  /* color: #d10000; */
  color: black;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: black;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
/* .react-calendar__tile--now {
  /* background: #ffff76; */
  /* background-color: #6f48eb; */
/* } */
.react-calendar__tile--now:enabled:hover, 
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
/* .react-calendar__tile--active {
  background: #6f48eb;
  color: white;
} */
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

abbr:where([title]) {
  text-decoration: none;
}

/* trainer */


.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000000;
  }
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device{
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* landing page */



.height{
  height:54rem;
  
}


.assign{
  border-radius: 8px;
  
  font-weight: bold;
  padding: 10px;
  margin-bottom: 20px;
  width: fit-content;

  
}
.assign:hover{
  border-radius: 8px;
  color: white;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 20px;
  width: fit-content;
  background-color: #E88E1A;
}
.in{
  border: none;
  border-bottom: 1px solid;
}
.blur-bg {
  backdrop-filter: blur(10px);
}
.modal_width{
  width: 600px;
}
.modal-content-scrollable {
  max-height: 500px; /* Adjust the max-height as needed */
  overflow-y: auto;
  
}
.color{
  background-color: #4755699a;
}


/* Student */


.custom-current-date {
  background-color: rgb(36, 15, 127) !important;
  color: white;
}
.absent {
  /* background-color: rgb(255, 183, 0) !important;  */
  color: black;
}
.present {
  background-color: green !important;
  color: white;
}
.profile{
  width: 100px!important;
  height: 100px;
  border-radius: 50%;
}
