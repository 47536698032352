/* CSS for the modal */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  .modal-content h2 {
    color: navy;
  }
  
  .modal-content p {
    color: white;
  }
  
  /* Close button for the modal */
  .modal-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .modal-close:hover,
  .modal-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  

  .student-table-course-div{
    display: none;
  }
  .student-table-course:hover .student-table-course-div {
    display: block;
  }
  .student-table-course-div:hover{
    display: block;
  }